/* eslint-disable import/no-extraneous-dependencies */
import { Backdrop } from '@material-ui/core';
import Lottie from 'lottie-react';
import santaClaus from '../../assets/json/santaClaus.json';

export const SantaClausLoading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Backdrop
      style={{
        position: 'fixed', // Garante que o Backdrop cobre toda a tela
        top: 0,
        left: 0,
        width: '100vw', // Cobre toda a largura da viewport
        height: '100vh', // Cobre toda a altura da viewport
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fundo semitransparente
        color: '#fff',
        zIndex: 9999999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isLoading}
    >
      <Lottie
        style={{
          width: '500px',
          height: '500px',
        }}
        animationData={santaClaus}
      />
    </Backdrop>
  );
};
