import { Switch, withRouter, Route as ReactRouterDom } from 'react-router-dom';
import { Route } from 'components/Route';
import { NotFound } from 'components/NotFound/index.js';
import { useAuth } from 'context/authProvider';
import { SantaClausLoading } from 'components/CustomLoading';
import { routes } from './routes';

export const RoutesContainer = withRouter(() => {
  const { customLoading } = useAuth();
  return (
    <>
      {customLoading && <SantaClausLoading isLoading={customLoading} />}
      <Switch>
        {routes.map(route => (
          <Route key={route.id} {...route} />
        ))}
        <ReactRouterDom component={NotFound} />
      </Switch>
    </>
  );
});
